import { format } from 'date-fns'
import {
  LovBranchesSearchResponse,
  useActiveBranchList
} from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { assertUnreachable, isNotNil } from 'driverama-core/utils/types'
import IconBranch from 'images/IconBranch.svg'
import IconDealer from 'images/IconDealer.svg'
import IconEditSimple from 'images/IconEditSimple.svg'
import IconHome from 'images/IconHome.svg'
import IconMobile from 'images/IconPhone.svg'
import IconSuperstore from 'images/IconSuperstore.svg'
import { EventProps } from 'react-big-calendar'
import { TFunction, useTranslation } from 'react-i18next'
import { getBranch } from '../../../api/driverama/booking/branches'
import {
  CustomRBEvent,
  isEventEditable,
  isOpportunity,
  isOrder,
  isReclamation
} from '../../../api/driverama/booking/slots'
import { STitle } from './Event.styled'
import { renderB2bCalledIcon } from './EventWrapper.utils'
import { IconsWrapper } from './IconWrapper'
import { Tag } from './Tag'

enum DELIVERY_TYPE {
  SUPER_STORE,
  BRANCH,
  MOBILE,
  DEALER_DELIVERY,
  HOME
}

function getDeliveryIcon(type: DELIVERY_TYPE) {
  switch (type) {
    case DELIVERY_TYPE.BRANCH:
      return IconBranch

    case DELIVERY_TYPE.DEALER_DELIVERY:
      return IconDealer

    case DELIVERY_TYPE.MOBILE:
      return IconMobile

    case DELIVERY_TYPE.SUPER_STORE:
      return IconSuperstore

    case DELIVERY_TYPE.HOME:
      return IconHome

    default:
      return assertUnreachable(type)
  }
}

export function getBranchType(
  t: TFunction<('appointment' | 'core')[]>,
  branches: LovBranchesSearchResponse['content'],
  branchId?: string | null
) {
  const branchReturn = {
    label: t('appointment:branch'),
    icon: getDeliveryIcon(DELIVERY_TYPE.BRANCH)
  }

  if (branchId) {
    const branch = getBranch(branches, branchId)

    if (branch?.type === 'SUPER_STORE') {
      return {
        label: t('appointment:superstore'),
        icon: getDeliveryIcon(DELIVERY_TYPE.SUPER_STORE)
      }
    }

    return branchReturn
  }

  return branchReturn
}

export function useEventDelivery(
  eventType: CustomRBEvent['type'],
  event: CustomRBEvent
) {
  const { branches } = useActiveBranchList()
  const { t } = useTranslation(['core', 'appointment'])

  if (isOpportunity(eventType)) {
    if (event.appointment?.location === 'BRANCH') {
      return getBranchType(t, branches, event.appointment.branchId)
    }

    if (event.appointment?.location === 'MOBILE') {
      return {
        label: t('appointment:mobile'),
        icon: getDeliveryIcon(DELIVERY_TYPE.MOBILE)
      }
    }
  }

  if (isOrder(eventType) || isReclamation(eventType)) {
    if (event.orderAppointment?.type === 'DEALER_DELIVERY') {
      return {
        label: t('appointment:dealer'),
        icon: getDeliveryIcon(DELIVERY_TYPE.DEALER_DELIVERY)
      }
    }

    if (event.orderAppointment?.location === 'BRANCH') {
      return getBranchType(t, branches, event.orderAppointment.branchId)
    }

    if (event.orderAppointment?.location === 'MOBILE') {
      return {
        label: t('appointment:home'),
        icon: getDeliveryIcon(DELIVERY_TYPE.HOME)
      }
    }
  }

  return undefined
}

function useEventSource(event: CustomRBEvent) {
  const { t } = useTranslation('appointment')

  switch (event.source) {
    case 'E_COMMERCE':
      return t('source_badge_E_COMMERCE')
    case 'WALK_IN':
      return t('source_badge_WALK_IN')
    case 'TICKING':
    case 'IN_CALL':
      return t('source_badge_TLM')
    case 'PROACTIVITY':
      return t('source_badge_PROACTIVITY')
    case 'B2B':
      return t('source_badge_B2B')
    default:
      return null
  }
}

export function EventComponent(props: EventProps<CustomRBEvent>) {
  const eventDelivery = useEventDelivery(props.event.type, props.event)
  const isEditable = isEventEditable(props.event.type, props.event.state)
  const source = useEventSource(props.event)

  return (
    <Flex
      variant="column"
      css={{
        height: '100%'
      }}
    >
      <Flex variant="row" justify="between" align="center">
        <Flex variant="row">
          {isNotNil(props.event.b2bPartnerCalled) && (
            <IconsWrapper>
              {renderB2bCalledIcon(props.event.b2bPartnerCalled)}
            </IconsWrapper>
          )}

          <Flex variant="row" wrap="wrap">
            {eventDelivery && <Tag>{eventDelivery.label}</Tag>}
            {source && <Tag>{source}</Tag>}
          </Flex>
        </Flex>
      </Flex>
      <Spacer size={1} />

      <TextBody size="small">
        <STitle>{props.title}</STitle>
      </TextBody>

      {props.event.start && !props.event.allDay && (
        <TextBody size="small" css={{ fontSize: 13 }}>
          {format(props.event.start, 'H:mm')}
        </TextBody>
      )}
      {isEditable && (
        <Flex
          variant="column"
          css={{
            flex: 1
          }}
          justify="end"
        >
          <TextBody
            size="small"
            css={{
              lineHeight: 0
            }}
          >
            <IconEditSimple
              css={{
                width: size(3.5),
                height: size(3.5)
              }}
            />
          </TextBody>
        </Flex>
      )}
    </Flex>
  )
}
