import { css } from '@emotion/react'
import { format } from 'date-fns'
import { Divider } from 'driverama-core/components/divider/Divider'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { ToggleText } from 'driverama-core/components/toggleText/ToggleText'
import IconPinSmall from 'driverama-core/images/icons/IconPin.svg'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import IconDirectionsCar from 'images/IconDirectionsCar.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isOpportunityEditable } from '../../../../api/driverama/booking/slots'
import { LINKS } from '../../../../constants/links'
import { useEventDelivery } from '../../event/Event'
import { PopupProps } from '../Popup'
import { getDetailedCar, getState, useDetailedAddress } from '../Popup.utils'
import { PopupHeader } from '../PopupHeader'
import { PopupListItem } from '../PopupListItem'
import { OpportunityEventNotes } from './OpportunityEventNotes'
import { OpportunityEventVehicle } from './OpportunityEventVehicle'

export function OpportunityEvent({ event, branchId, ...props }: PopupProps) {
  const { t } = useTranslation(['core', 'appointment'])

  const [newState, setNewState] = useState(
    getState(event.state, event.lossReason)
  )

  const [modalContent, setModalContent] = useState<'vehicle' | 'notes'>(
    'vehicle'
  )

  const eventDelivery = useEventDelivery(event.type, event)

  const address = useDetailedAddress(event.appointment)
  const car = getDetailedCar(event)

  return (
    <>
      <PopupHeader
        onClose={props.onClose}
        editLink={
          isOpportunityEditable(event.state) && event.state !== 'LOST'
            ? LINKS.appointment(branchId, event.id)
            : undefined
        }
      />
      <Spacer size={2} />
      <TextHeader variant="h5">{event.title}</TextHeader>
      {event.start && event.end && (
        <TextBody
          variant="body"
          size="large"
          css={css`
            color: ${color('black')};
          `}
        >
          {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
        </TextBody>
      )}
      <Spacer size={4} />

      <Flex variant="column" gap={2}>
        {!!car.length && (
          <PopupListItem Icon={IconDirectionsCar}>{car}</PopupListItem>
        )}

        {!!address?.length && (
          <PopupListItem Icon={IconPinSmall}>{address}</PopupListItem>
        )}

        {eventDelivery && (
          <PopupListItem Icon={eventDelivery.icon}>
            {eventDelivery.label}
          </PopupListItem>
        )}

        <Spacer size={1} />
        <Divider />
        <Spacer size={1} />

        <ToggleText
          classes={{
            container: css`
              min-height: ${size(9)};
              padding: 0;
              background-color: ${color('almond-l-200')};
            `
          }}
          value={modalContent}
          onChange={setModalContent}
          options={[
            {
              label: t('appointment:opportunity_modal_content_vehicle'),
              value: 'vehicle'
            },
            {
              label: t('appointment:opportunity_modal_content_notes'),
              value: 'notes'
            }
          ]}
        />
      </Flex>

      <Spacer size={4} />

      {modalContent === 'vehicle' && (
        <OpportunityEventVehicle
          newState={newState}
          onClose={props.onClose}
          setNewState={setNewState}
          event={event}
          onChangeEvent={props.onChangeEvent}
        />
      )}

      {modalContent === 'notes' && <OpportunityEventNotes event={event} />}
    </>
  )
}
