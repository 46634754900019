import enGB from 'date-fns/locale/en-GB'
import { dateFnsLocalizer } from 'react-big-calendar'
import { addHours, format, getDay, isAfter, parse, startOfWeek } from 'date-fns'

const locales = {
  'en-GB': enGB
}

function getEventEndTime(start: Date, end: Date) {
  const minEnd = addHours(start, 1)
  if (isAfter(end, minEnd)) {
    return end
  }
  return minEnd
}

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

// FIX: minimum one hour event - I really hate it
localizer.formats.selectRangeFormat = ({ start, end }) =>
  `${format(start, 'p')} - ${format(getEventEndTime(start, end), 'p')}`
