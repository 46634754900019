import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, weight } from 'driverama-core/styles/variables'

export const SCalledButton = styled(Button)<{ isActive: boolean }>`
  min-width: ${size(21)};
  padding: ${size(2)} ${size(4)} ${size(2)} ${size(3)};
  border: 2px solid ${color('night-l-700')} !important;
  border-radius: ${radius('full')};

  & svg {
    margin-right: ${size(1.5)};
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: ${color('night-l-200')};
        font-weight: ${weight('bold')};
        background-color: ${color('night-l-700')};
      `
    }

    return css`
      color: ${color('night-text')};
      font-weight: ${weight('regular')};
    `
  }}
`
