import {
  CustomRBEvent,
  isOpportunityEditable
} from 'api/driverama/booking/slots'
import { useCancelOpportunityMutation } from 'api/driverama/opportunity/cancel'
import { useReopenOpportunityMutation } from 'api/driverama/opportunity/reopen'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { SelectUncontrolled } from 'driverama-core/components/select/SelectUncontrolled'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBodyMedium } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import IconCopy from 'driverama-core/images/icons/IconCopy.svg'
import { color } from 'driverama-core/styles/variables'
import { useCopyToClipboard } from 'driverama-core/utils/hooks'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getOptions,
  getState,
  isB2Bopportunity,
  isLostReason,
  isNewState,
  isReopenState,
  LOST_REASONS_TYPE,
  NewState,
  REOPEN_STATES_TYPE
} from '../Popup.utils'

import { PopupListItem } from '../PopupListItem'
import { OpportunityB2BCalled } from './OpportunityCalled'

interface Props {
  event: CustomRBEvent
  onChangeEvent: (values: Partial<CustomRBEvent>) => void
  newState: NewState
  setNewState: Dispatch<SetStateAction<NewState>>
  onClose: () => void
}

export function OpportunityEventVehicle({
  event,
  onChangeEvent,
  newState,
  setNewState,
  onClose
}: Props) {
  const { t } = useTranslation(['core', 'appointment'])
  const { copyToClipboard } = useCopyToClipboard()

  const cancelOpportunityQuery = useCancelOpportunityMutation(event.id)

  const reopenOpportunityQuery = useReopenOpportunityMutation(event.id)

  const queries = [cancelOpportunityQuery, reopenOpportunityQuery]
  const isLoading = queries.some(query => query.isLoading)

  const handleUpdateState = async (
    state: LOST_REASONS_TYPE | REOPEN_STATES_TYPE
  ) => {
    try {
      if (isNewState(event, state)) {
        if (isLostReason(state)) {
          const response = await cancelOpportunityQuery.mutateAsync({
            lossReason: state
          })
          if (response) {
            onChangeEvent({
              state: response.state,
              lossReason: response.lossReason
            })
          }
        }

        if (isReopenState(state)) {
          const response = await reopenOpportunityQuery.mutateAsync({
            state: state
          })

          if (response) {
            onChangeEvent({
              state: response.state,
              lossReason: response.lossReason
            })
          }
        }

        onClose()

        toast({
          type: 'success',
          content: t('appointment:opportunity_updated')
        })
      }
    } catch (error) {
      toast({ type: 'error', content: t('core:error_common'), error })
    }
  }

  return (
    <>
      {event.erpId && (
        <PopupListItem label={t('core:erpID')} justify="between">
          <Flex variant="row" gap={2} align="center">
            <TextBodyMedium size="small" css={{ color: color('night-l-100') }}>
              {event.erpId}
            </TextBodyMedium>
            <ButtonTransparent
              css={{ color: color('night-l-100') }}
              onClick={() => copyToClipboard(event.erpId ?? '')}
            >
              <IconCopy />
            </ButtonTransparent>
          </Flex>
        </PopupListItem>
      )}

      {isOpportunityEditable(event.state) && (
        <>
          <Spacer size={4} />
          <SelectUncontrolled
            disabled={isLoading}
            emptyLabel={
              getState(event.state, event.lossReason)
                ? t(
                    `appointment:status_${getState(
                      event.state,
                      event.lossReason
                    )}` as `appointment:status_${
                      | LOST_REASONS_TYPE
                      | REOPEN_STATES_TYPE}`
                  )
                : t('core:select_empty_value')
            }
            name="status"
            value={newState}
            options={getOptions(t, event.state)}
            onChange={value => {
              const newValue = value as LOST_REASONS_TYPE | REOPEN_STATES_TYPE

              setNewState(newValue)
              handleUpdateState(newValue)
            }}
          />
        </>
      )}

      {isB2Bopportunity(event) && (
        <OpportunityB2BCalled
          event={event}
          onChangeEvent={onChangeEvent}
          onClose={onClose}
        />
      )}
    </>
  )
}
