import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, zIndex } from 'driverama-core/styles/variables'

export const SPopup = styled.div`
  background: ${color('white')};
  box-shadow: 0 0 ${size(4)} ${color('black', 0.08)};
  border-radius: ${radius('corner')};
  min-width: ${size(100)};
  padding: ${size(6)};
  z-index: ${zIndex('order-1')};
`
