import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { font, weight } from 'driverama-core/styles/variables'

export const Tag = styled.span`
  padding: ${size(0.5)} ${size(1)};
  font: ${font('heading')};
  font-size: 10px;
  font-weight: ${weight('bold')};
  border-radius: ${size(0.5)};
  margin-right: ${size(1)};
  margin-bottom: ${size(1)};
  margin-left: 0 !important;
`
