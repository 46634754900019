import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { shadow } from 'driverama-core/styles/variables'

export const SSidebar = styled.aside`
  position: fixed;
  height: 100%;
  width: ${size(80)};
  box-shadow: ${shadow(3)};
`

export const SSidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: ${size(8)};
`
