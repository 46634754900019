import { paths } from 'driverama-core/api/driverama/generated/appointments'
import { HTTPMethod } from 'driverama-core/constants/rest'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { URLS } from '../../../constants/api'
import { QUERY_KEYS } from '../../../constants/queryKeys'

type BookingUnavailabilityCreateBody = paths['/admin/appointments/booking-unavailability']['post']['requestBody']['content']['application/json']

type BookingUnavailabilityCreateResponse =
  | paths['/admin/appointments/booking-unavailability']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type CreateMutationOpts = UseMutationOptions<
  BookingUnavailabilityCreateResponse,
  unknown,
  BookingUnavailabilityCreateBody
>

export function useBookingUnavailabilityCreateMutation(
  opts?: CreateMutationOpts
) {
  return useMutation<
    BookingUnavailabilityCreateResponse,
    unknown,
    BookingUnavailabilityCreateBody
  >(async body => {
    const res = await apiAuthFetcher<BookingUnavailabilityCreateResponse>(
      URLS.bookingUnavailabilityCreate,
      { method: HTTPMethod.POST, body }
    )

    return res.json
  }, opts)
}

type BookingUnavailabilityUpdateBody = paths['/admin/appointments/booking-unavailability/{appointmentBookingUnavailabilityId}']['put']['requestBody']['content']['application/json']

type BookingUnavailabilityUpdateResponse =
  | paths['/admin/appointments/booking-unavailability/{appointmentBookingUnavailabilityId}']['put']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type UpdateMutationOpts = UseMutationOptions<
  BookingUnavailabilityUpdateResponse,
  unknown,
  BookingUnavailabilityUpdateBody
>

export function useBookingUnavailabilityUpdateMutation(
  id: string,
  opts?: UpdateMutationOpts
) {
  return useMutation<
    BookingUnavailabilityUpdateResponse,
    unknown,
    BookingUnavailabilityUpdateBody
  >(async body => {
    const res = await apiAuthFetcher<BookingUnavailabilityUpdateResponse>(
      URLS.bookingUnavailability(id),
      { method: HTTPMethod.PUT, body }
    )
    return res.json
  }, opts)
}

type BookingUnavailabilityDeleteResponse =
  | paths['/admin/appointments/booking-unavailability/{appointmentBookingUnavailabilityId}']['delete']['responses']['204']['content']['application/com.driverama-v1+json']
  | undefined

type DeleteMutationOpts = UseMutationOptions<
  BookingUnavailabilityDeleteResponse,
  unknown,
  unknown
>

export function useBookingUnavailabilityDeleteMutation(
  id: string,
  opts?: DeleteMutationOpts
) {
  return useMutation<BookingUnavailabilityDeleteResponse>(async () => {
    const res = await apiAuthFetcher<BookingUnavailabilityDeleteResponse>(
      URLS.bookingUnavailability(id),
      { method: HTTPMethod.DELETE }
    )
    return res.json
  }, opts)
}

type BookingUnavailabilityGetResponse = paths['/admin/appointments/booking-unavailability/{appointmentBookingUnavailabilityId}']['get']['responses']['200']['content']['application/com.driverama-v1+json']

type BookingUnavailabilityQueryOptions = UseQueryOptions<
  unknown,
  unknown,
  BookingUnavailabilityGetResponse
>

export function useBookingUnavailability(
  id: string,
  opts?: BookingUnavailabilityQueryOptions
) {
  return useQuery(
    QUERY_KEYS.bookingUnavailability(id),
    async () => {
      const res = await apiAuthFetcher<BookingUnavailabilityGetResponse>(
        URLS.bookingUnavailability(id),
        { method: HTTPMethod.GET }
      )
      return res.json
    },
    opts
  )
}
