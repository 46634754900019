import { format, getDay, parse, startOfWeek } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import { createContext, ReactNode, useCallback, useState } from 'react'
import {
  Calendar as RBCalendar,
  CalendarProps,
  dateFnsLocalizer
} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { EventComponent } from 'components/calendar/event/Event'
import { Header } from 'components/calendar/header/Header'
import {
  DateWithSlotsProps,
  isHomeDeliveryDisabled,
  isSlotUnavailable
} from 'components/calendar/slot/Slot.utils'
import { CalendarToolbar } from 'components/calendar/toolbar/Toolbar'

import { useDeepCompareEffect } from 'react-use'
import { CustomRBEvent } from '../../api/driverama/booking/slots'
import { SCalendar } from './Calendar.styled'
import {
  CalendarOpeningHours,
  getMinAndMaxOpeningHours,
  isTimeGutter
} from './Calendar.utils'
import { EventWrapper } from './event/EventWrapper'
import { isClosed } from './slot/Slot.utils'

const locales = {
  'en-GB': enGB
}

const defaultLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

interface CalendarOuterProps extends CalendarProps<CustomRBEvent> {
  toolbarLeftActions?: ReactNode
  openingDaysWithHours?: CalendarOpeningHours
  slots?: DateWithSlotsProps[]
  isLoading: boolean
  renderContent?: boolean
}

interface CalendarContextProps {
  setSelected?: (event?: CustomRBEvent) => void
  updateEvent?: (id: string, values: Partial<CustomRBEvent>) => void
  setEvents?: (events: CustomRBEvent[]) => void
}

export const CalendarContext = createContext<CalendarContextProps>({})

export function Calendar({
  toolbarLeftActions,
  localizer,
  openingDaysWithHours,
  isLoading,
  renderContent = true,
  slots,
  events: initialEvents = [],
  ...props
}: CalendarOuterProps) {
  const minAndMaxOpeningHours = getMinAndMaxOpeningHours(openingDaysWithHours)
  const [events, setEvents] = useState(initialEvents)

  const [selected, setSelected] = useState<CustomRBEvent>()

  useDeepCompareEffect(() => {
    setEvents(initialEvents)
  }, [initialEvents])

  const updateEvent = useCallback(
    (id: string, values: Partial<CustomRBEvent>) => {
      setEvents(
        events.map(x =>
          x.id === id
            ? {
                ...x,
                ...values
              }
            : x
        )
      )
    },
    [events]
  )

  return (
    <SCalendar isLoading={isLoading} renderContent={renderContent}>
      <CalendarContext.Provider
        value={{
          setSelected,
          updateEvent
        }}
      >
        <RBCalendar
          selectable={!!props.onSelectSlot}
          localizer={localizer ?? defaultLocalizer}
          culture="en-GB"
          selected={selected}
          min={minAndMaxOpeningHours.min}
          max={minAndMaxOpeningHours.max}
          dayLayoutAlgorithm="no-overlap"
          formats={{
            timeGutterFormat: (date, culture, localizer) => {
              if (localizer) {
                return localizer.format(date, 'H:mm', culture ?? 'en-US')
              }
              return format(date, 'H:mm')
            }
          }}
          eventPropGetter={event => {
            const extraClasses: string[] = []

            if (event.state) {
              extraClasses.push(`__${event.state?.toLocaleLowerCase()}`)
            }

            return {
              className: extraClasses.join(' ')
            }
          }}
          dayPropGetter={date => {
            const extraClasses: string[] = []
            if (isHomeDeliveryDisabled(slots ?? [], date)) {
              extraClasses.push('__disabled_homedelivery')
            }
            return {
              className: extraClasses.join(' ')
            }
          }}
          slotPropGetter={(date: Date, resource) => {
            const extraClasses: string[] = []

            if (openingDaysWithHours && !isTimeGutter(resource)) {
              const isSlotClosed = isClosed(openingDaysWithHours, date)
              if (isSlotClosed) {
                extraClasses.push('__closed')
              } else {
                if (slots && isSlotUnavailable(slots, date)) {
                  extraClasses.push('__unavailable')
                }
              }
            }

            return {
              className: extraClasses.join(' ')
            }
          }}
          components={{
            toolbar: props => (
              <CalendarToolbar {...props} leftActions={toolbarLeftActions} />
            ),
            event: EventComponent,
            eventWrapper: EventWrapper,
            header: props => (
              <Header {...props} openingDays={openingDaysWithHours} />
            )
          }}
          events={events}
          {...props}
        />
      </CalendarContext.Provider>
    </SCalendar>
  )
}
