import { ToolbarProps } from 'react-big-calendar'
import { Flex } from 'driverama-core/components/Flex'
import { ButtonCircle } from 'driverama-core/components/button/Button'
import { TextBody } from 'driverama-core/components/text/Text'
import {
  SToolbar,
  SToolbarButton,
  SToolbarDate,
  SToolbarLabel
} from './Toolbar.styled'

import IconArrowLeft from 'driverama-core/images/icons/IconArrowLeft.svg'
import IconArrowRight from 'driverama-core/images/icons/IconArrowRight.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { color } from 'driverama-core/styles/variables'

interface CalendarToolbarProps extends ToolbarProps {
  leftActions?: ReactNode
}

export function CalendarToolbar({
  label,
  onNavigate,
  leftActions
}: CalendarToolbarProps) {
  const { t } = useTranslation('core')

  return (
    <SToolbar>
      <Flex variant="row" justify="between">
        {leftActions}
        <SToolbarDate>
          <Flex variant="row" justify="between" gap={8} align="center">
            <ButtonCircle variant="outline" onClick={() => onNavigate('PREV')}>
              <IconArrowLeft
                css={{
                  color: color('night-text')
                }}
              />
            </ButtonCircle>
            <SToolbarLabel variant="h5">{label}</SToolbarLabel>
            <ButtonCircle variant="outline" onClick={() => onNavigate('NEXT')}>
              <IconArrowRight
                css={{
                  color: color('night-text')
                }}
              />
            </ButtonCircle>
          </Flex>
        </SToolbarDate>
        <SToolbarButton variant="outline" onClick={() => onNavigate('TODAY')}>
          <TextBody>{t('today')}</TextBody>
        </SToolbarButton>
      </Flex>
    </SToolbar>
  )
}
