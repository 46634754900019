import { URLS } from 'constants/api'
import { operations } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type ReopenOpportunityResponse =
  | operations['reopenOpportunity']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type ReopenOpportunityBody = operations['reopenOpportunity']['requestBody']['content']['application/json']

type ReopenOpportunityMutationOpts = UseMutationOptions<
  ReopenOpportunityResponse,
  unknown,
  ReopenOpportunityBody
>

export function useReopenOpportunityMutation(
  opportunityId: string,
  opts?: ReopenOpportunityMutationOpts
) {
  return useMutation<ReopenOpportunityResponse, unknown, ReopenOpportunityBody>(
    async body => {
      const res = await apiAuthFetcher<ReopenOpportunityResponse>(
        URLS.reopenAdminOpportunity(opportunityId),
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    opts
  )
}
