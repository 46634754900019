import { URLS } from 'constants/api'
import { operations } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type UpdateOpportunityResponse =
  | operations['patchOpportunity']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

export type UpdateOpportunityBody = operations['patchOpportunity']['requestBody']['content']['application/json']

type ReopenOpportunityMutationOpts = UseMutationOptions<
  UpdateOpportunityResponse,
  unknown,
  UpdateOpportunityBody
>

export function useUpdateOpportunityMutation(
  opportunityId: string,
  opts?: ReopenOpportunityMutationOpts
) {
  return useMutation<UpdateOpportunityResponse, unknown, UpdateOpportunityBody>(
    async body => {
      const res = await apiAuthFetcher<UpdateOpportunityResponse>(
        URLS.pathOpportunity(opportunityId),
        {
          method: HTTPMethod.PATCH,
          body
        }
      )
      return res.json
    },
    opts
  )
}
