import styled from '@emotion/styled'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { color, font, weight } from 'driverama-core/styles/variables'

import { css } from '@emotion/react'
import { SHeader, SHeaderTitle } from 'components/calendar/header/Header'
import { getSpinnerStyles } from 'driverama-core/components/spinner/Spinner'

import { Tag } from './event/Tag'

export const SCalendar = styled.div<{
  isLoading: boolean
  renderContent: boolean
}>`
  --gutter-width: ${size(20)};

  .rbc-row {
    min-height: auto;
  }

  .rbc-current-time-indicator {
    display: none;
  }

  .rbc-today {
    background: none;
  }

  ${({ renderContent, isLoading }) =>
    (!renderContent || isLoading) &&
    css`
      .rbc-time-view {
        .rbc-time-header,
        .rbc-time-content {
          display: none;
        }
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      .rbc-time-view::after {
        content: '';
        position: relative;
        left: ${size(10)};
        margin-left: auto;
        margin-right: auto;
        margin-top: ${size(20)};
        ${getSpinnerStyles('large')}
      }
    `}

  .rbc-header {
    border-bottom: none;

    &.rbc-today ${SHeader} {
      background: ${color('night-l-100')};

      ${TextBody}, ${TextHeader}, ${SHeaderTitle} {
        color: ${color('white')};
      }
    }

    + .rbc-header {
      border: none;
    }
  }

  .rbc-day-bg {
    border: none;
  }

  .rbc-day-slot {
    &.__disabled_homedelivery {
      &::before {
        content: '';
        position: absolute;
        left: ${size(0.5)};
        right: ${size(0.5)};
        top: 0;
        bottom: ${size(0.5)};
        z-index: 0;
        background-color: ${color('sun-l-400', 0.5)};
      }
    }

    .rbc-time-slot {
      &.__closed {
        background-color: ${color('white')};
        background-image: linear-gradient(
          135deg,
          ${color('night-l-650')} 4.55%,
          transparent 4.55%,
          transparent 50%,
          ${color('night-l-650')} 50%,
          ${color('night-l-650')} 54.55%,
          transparent 54.55%,
          transparent 100%
        );
        background-size: 19px 19px;

        + .__closed {
          background-position: 11px;
          border-top: none;
        }
      }

      ~ .__closed {
        border-top: 1px solid ${color('night-l-650')};
      }

      &.__unavailable {
        &::after,
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 0;
        }

        &::before {
          background: linear-gradient(
            to bottom right,
            transparent,
            transparent 49%,
            ${color('night-l-650')} 49%,
            ${color('night-l-650')} 50%,
            transparent 50%,
            transparent
          );
        }

        &::after {
          background: linear-gradient(
            to bottom left,
            transparent,
            transparent 49%,
            ${color('night-l-650')} 49%,
            ${color('night-l-650')} 50%,
            transparent 50%,
            transparent
          );
        }
      }

      + .rbc-time-slot {
        border-top: 1px solid ${color('night-l-700')};
      }
    }
  }

  .rbc-time {
    &-view {
      border: none;
    }

    &-column {
      .rbc-timeslot-group {
        min-height: 150px;
        border-bottom: none;

        &:first-of-type .rbc-time-slot:first-of-type {
          border-top: none;
        }
      }
    }

    &-content {
      border: 0;
      overflow-y: visible;

      * {
        border-color: ${color('night-l-650')};
      }
    }

    &-header {
      padding-top: ${size(5)};

      &-cell {
        padding-bottom: ${size(4)};
      }

      &-content {
        border-left: none;
      }
    }

    &-gutter {
      width: var(--gutter-width);
    }

    &-slot {
      font-family: ${font('text')};
      color: ${color('night-l-100')};
      font-weight: ${weight('bold')};
      position: relative;

      .rbc-label {
        position: absolute;
        right: ${size(5)};
        top: -10px;
      }
    }
  }

  .rbc-event {
    padding: ${size(2)};

    &-content {
      z-index: 1;
    }

    &.__opportunity {
      background: ${color('night-l-700')};
      border-color: ${color('night-l-700')};

      ${TextBody} {
        color: ${color('night')};
      }

      ${Tag} {
        background: ${color('night')};
      }

      &.__lost {
        &:before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: repeating-linear-gradient(
            135deg,
            transparent,
            transparent 20px,
            ${color('black', 0.1)} 20px,
            ${color('black', 0.1)} 40px
          );
          content: '';
        }

        ${Tag} {
          background: ${color('night-text')};
        }

        ${TextBody} {
          color: ${color('night-text')};
        }
      }

      &.rbc-selected {
        background: ${color('night')};
        border-color: ${color('night')};

        ${TextBody} {
          color: ${color('white')};
        }

        ${Tag} {
          background: ${color('night-text-light')};
          color: ${color('night')};
        }
      }
    }

    &.__disabled_home_delivery {
      background: ${color('sun-l-400', 0.5)};
      border-color: ${color('sun-l-400')};

      ${TextBody} {
        color: ${color('sun-d-400')};
      }

      &.rbc-selected {
        background: ${color('sun-d-200')};
        border-color: ${color('sun-d-200')};

        ${TextBody} {
          color: ${color('white')};
        }
      }
    }

    &.__handover {
      &.__branch,
      &.__home {
        background: ${color('sun-l-400')};
        border-color: ${color('sun-l-400')};

        ${Tag} {
          background: ${color('sun-d-200')};
        }

        ${TextBody} {
          color: ${color('sun-d-200')};
        }

        &.rbc-selected {
          background: ${color('sun-d-200')};
          border-color: ${color('sun-d-200')};

          ${Tag} {
            background: ${color('sun-l-400')};
            color: ${color('sun-d-200')};
          }

          ${TextBody} {
            color: ${color('white')};
          }
        }
      }

      &.__return {
        background: ${color('warning-l-100')};
        border-color: ${color('warning-l-100')};

        ${Tag} {
          background: ${color('warning')};
          color: ${color('white')};
        }

        ${TextBody} {
          color: ${color('warning')};
        }

        &.rbc-selected {
          background: ${color('warning')};
          border-color: ${color('warning')};

          ${Tag} {
            background: ${color('warning-l-100')};
            color: ${color('warning')};
          }

          ${TextBody} {
            color: ${color('white')};
          }
        }
      }
    }

    &.__reclamation {
      background: ${color('warning')};
      border-color: ${color('warning')};

      ${Tag} {
        background: ${color('white')};
        color: ${color('warning')};
      }

      ${TextBody} {
        color: ${color('white')};
      }

      &.rbc-selected {
        background: ${color('warning-d')};
        border-color: ${color('warning-d')};

        ${Tag} {
          color: ${color('warning-d')};
        }
      }
    }

    &.rbc-selected {
      background: ${color('night-l-200')};
    }

    &-label {
      display: none;
    }
  }

  .rbc-time-content {
    border-right: 1px solid ${color('night-l-650')};
  }

  .rbc-time-column:not(.rbc-time-gutter) {
    border-top: 1px solid ${color('night-l-650')};
    border-bottom: 1px solid ${color('night-l-650')};
  }

  .rbc-slot-selection {
    min-height: 10%;
    max-height: 10%;
  }
`
