import { css } from '@emotion/react'
import { CustomRBEvent } from 'api/driverama/booking/slots'
import { useEmployeesSearchQuery } from 'api/driverama/employees/search'
import { useNoteCreateMutation } from 'api/driverama/note/create'
import { useNotesSearchQuery } from 'api/driverama/note/search'
import { Note } from 'components/note/Note'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { Tag } from 'driverama-core/components/tag/Tag'
import { toast } from 'driverama-core/components/toast/Toast'
import { color, radius } from 'driverama-core/styles/variables'
import { isNotNil, isString } from 'driverama-core/utils/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  event: CustomRBEvent
}

export function OpportunityEventNotes({ event }: Props) {
  const { t } = useTranslation(['core', 'appointment', 'notes'])

  const [note, setNote] = useState('')

  const { mutateAsync: createNoteAsync } = useNoteCreateMutation(event.id)

  const notesQuery = useNotesSearchQuery({
    filter: {
      opportunityIds: [event.id],
      employeeIds: []
    }
  })

  const employeesIds =
    notesQuery.data?.content
      .filter(note => !!note.employeeId)
      .map(note => note.employeeId)
      .filter(isNotNil) ?? []

  const employeesQuery = useEmployeesSearchQuery(
    {
      ids: employeesIds
    },
    {
      enabled: !!employeesIds.length
    }
  )

  const queries = [notesQuery, employeesQuery]
  const isLoading = queries.some(query => query.isLoading)

  if (isLoading) {
    return (
      <SpinnerCentered
        // need to override ".css-1aq46do-SCalendar .rbc-time-content *" selector
        css={css`
          & > div {
            border-color: ${color('night-l-800')} !important;
            border-left-color: ${color('night-l-100')} !important;
          }
        `}
      />
    )
  }

  const handleAddNote = async () => {
    try {
      if (isString(note) && !!note.length) {
        await createNoteAsync({
          text: note,
          noteSource: 'APPOINTMENTS_APP'
        })

        setNote('')

        await notesQuery.refetch()

        toast({
          type: 'success',
          content: t('appointment:opportunity_updated')
        })
      }
    } catch (error) {
      toast({
        error,
        content: t('core:error_common'),
        type: 'error'
      })
    }
  }

  return (
    <>
      <Flex
        variant="column"
        gap={2}
        css={{ maxHeight: 120, overflowY: 'scroll' }}
      >
        {notesQuery.data?.content
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
          .slice(0, 5)
          .map(x => {
            const createdByEmployee = employeesQuery.data?.content.find(
              employee => employee.id === x.employeeId
            )

            const createdBy = [
              createdByEmployee?.firstName,
              createdByEmployee?.surname
            ]
              .filter(isNotNil)
              .join(' ')

            return (
              <Note
                key={x.id}
                createdAt={x.createdAt}
                createdBy={createdBy}
                tag={
                  createdByEmployee ? (
                    <Tag variant="primary">{t('notes:note_employee_tag')}</Tag>
                  ) : null
                }
                css={css`
                  border-radius: ${radius('corner-smaller')};
                `}
              >
                {x.text}
              </Note>
            )
          })}
      </Flex>

      <Spacer size={4} />

      <Flex variant="row" gap={2} align="center">
        <FormControl label={t('appointment:new_note')} css={{ flex: 3 }}>
          <input
            type="text"
            value={note}
            onChange={event => {
              setNote(event.target.value)
            }}
          />
        </FormControl>
        <Button
          variant="primary"
          css={css`
            flex: 1;
            border-color: ${color('night-l-100')} !important;

            @media (hover: hover) {
              &:hover {
                border-color: ${color('night-l-200')} !important;
              }
            }
          `}
          onClick={handleAddNote}
        >
          {t('core:add')}
        </Button>
      </Flex>
    </>
  )
}
