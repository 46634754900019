import { URLS } from 'constants/api'
import { operations } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { useMutation, UseMutationOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'

type CancelOpportunityResponse =
  | operations['cancelOpportunity_1']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type CancelOpportunityBody = operations['cancelOpportunity_1']['requestBody']['content']['application/json']

type CancelOpportunityMutationOpts = UseMutationOptions<
  CancelOpportunityResponse,
  unknown,
  CancelOpportunityBody
>

export function useCancelOpportunityMutation(
  opportunityId: string,
  opts?: CancelOpportunityMutationOpts
) {
  return useMutation<CancelOpportunityResponse, unknown, CancelOpportunityBody>(
    async body => {
      const res = await apiAuthFetcher<CancelOpportunityResponse>(
        URLS.cancelAdminOpportunity(opportunityId),
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    opts
  )
}
