import { css } from '@emotion/react'
import { format } from 'date-fns'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import {
  TextBody,
  TextBodyMedium,
  TextHeader
} from 'driverama-core/components/text/Text'
import IconCopy from 'driverama-core/images/icons/IconCopy.svg'
import { color } from 'driverama-core/styles/variables'
import { useTranslation } from 'react-i18next'
import { useBranch } from '../../../api/driverama/booking/branches'
import IconDirectionsCar from '../../../images/IconDirectionsCar.svg'
import IconUser from '../../../images/IconUser.svg'
import { PopupProps } from './Popup'
import { PopupHeader } from './PopupHeader'
import { PopupListItem } from './PopupListItem'

import { isReclamation } from 'api/driverama/booking/slots'
import {
  Button,
  ButtonTransparent
} from 'driverama-core/components/button/Button'
import { Divider } from 'driverama-core/components/divider/Divider'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { toast } from 'driverama-core/components/toast/Toast'
import IconPin from 'driverama-core/images/icons/IconPin.svg'
import { useCopyToClipboard } from 'driverama-core/utils/hooks'
import { isNotNil, isString } from 'driverama-core/utils/types'
import { useState } from 'react'
import { useNoteCreateMutation } from '../../../api/driverama/orderNote/create'
import { LINKS } from '../../../constants/links'
import { useEventDelivery } from '../event/Event'
import { getDetailedCar } from './Popup.utils'

export function OrderEvent({ event, ...props }: PopupProps) {
  const { t } = useTranslation(['core', 'appointment'])

  const branchQuery = useBranch(event.orderAppointment?.branchId)
  const car = getDetailedCar(event)
  const eventDelivery = useEventDelivery(event.type, event)
  const [note, setNote] = useState('')

  const {
    mutateAsync: createNoteAsync,
    isLoading: noteCreateLoading
  } = useNoteCreateMutation(event.id)

  const { copyToClipboard } = useCopyToClipboard()

  const handleSubmit = async () => {
    try {
      if (isString(note) && !!note.length) {
        await createNoteAsync({
          value: note
        })

        toast({
          type: 'success',
          content: t('appointment:appointment_updated')
        })

        props.onClose()
      }
    } catch (error) {
      toast({ type: 'error', content: t('core:error_common'), error })
    }
  }

  return (
    <>
      <PopupHeader
        onClose={props.onClose}
        editLink={
          branchQuery?.branch?.id
            ? isReclamation(event.type) && event.start
              ? LINKS.reclamation_appointment(branchQuery.branch.id, event.id)
              : LINKS.handover_appointment(branchQuery.branch.id, event.id)
            : undefined
        }
      />
      <Spacer size={2} />
      <TextHeader variant="h5">{event.title}</TextHeader>
      {event.start && event.end && (
        <TextBody
          variant="body"
          size="large"
          css={css`
            color: ${color('black')};
          `}
        >
          {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
        </TextBody>
      )}
      <Spacer size={4} />
      <Flex variant="column" gap={2}>
        {!!car.length && (
          <PopupListItem Icon={IconDirectionsCar}>{car}</PopupListItem>
        )}
        {event.orderAppointment?.location === 'BRANCH' &&
        branchQuery?.branch ? (
          <PopupListItem Icon={IconPin}>
            {branchQuery.branch?.fullAddress}
          </PopupListItem>
        ) : (
          <PopupListItem Icon={IconPin}>
            {[
              event.orderAppointment?.address?.streetNameAndNumber,
              [
                event.orderAppointment?.address?.postCode,
                event.orderAppointment?.address?.city
              ]
                .filter(isNotNil)
                .join(' ')
            ]
              .filter(isNotNil)
              .join(', ')}
          </PopupListItem>
        )}

        {event.responsibleEmployee && (
          <PopupListItem Icon={IconUser}>
            {event.responsibleEmployee}
          </PopupListItem>
        )}

        {eventDelivery && (
          <PopupListItem Icon={eventDelivery.icon}>
            {eventDelivery.label}
          </PopupListItem>
        )}
      </Flex>

      <Spacer size={4} />
      <Divider />
      <Spacer size={4} />

      <Flex variant="column" gap={2}>
        {event.car?.vin && (
          <PopupListItem label={t('core:vin')} justify="between">
            <Flex variant="row" gap={2} align="center">
              <TextBodyMedium
                size="small"
                css={{ color: color('night-l-100') }}
              >
                {event.car.vin}
              </TextBodyMedium>
              <ButtonTransparent
                css={{ color: color('night-l-100') }}
                onClick={() => copyToClipboard(event.car?.vin ?? '')}
              >
                <IconCopy />
              </ButtonTransparent>
            </Flex>
          </PopupListItem>
        )}

        {event.car?.stockNo && (
          <PopupListItem label={t('core:stockID')} justify="between">
            <Flex variant="row" gap={2} align="center">
              <TextBodyMedium
                size="small"
                css={{ color: color('night-l-100') }}
              >
                {event.car.stockNo}
              </TextBodyMedium>
              <ButtonTransparent
                css={{ color: color('night-l-100') }}
                onClick={() => copyToClipboard(event.car?.stockNo ?? '')}
              >
                <IconCopy />
              </ButtonTransparent>
            </Flex>
          </PopupListItem>
        )}

        {event.erpId && (
          <PopupListItem label={t('core:erpID')} justify="between">
            <Flex variant="row" gap={2} align="center">
              <TextBodyMedium
                size="small"
                css={{ color: color('night-l-100') }}
              >
                {event.erpId}
              </TextBodyMedium>
              <ButtonTransparent
                css={{ color: color('night-l-100') }}
                onClick={() => copyToClipboard(event.erpId ?? '')}
              >
                <IconCopy />
              </ButtonTransparent>
            </Flex>
          </PopupListItem>
        )}
        <Spacer size={4} />
        <FormControl label={t('appointment:add_note')}>
          <input
            type="text"
            value={note}
            onChange={event => {
              setNote(event.target.value)
            }}
          />
        </FormControl>
        <Spacer size={4} />
        <Button
          variant="primary"
          css={{
            width: '100%'
          }}
          onClick={handleSubmit}
          disabled={noteCreateLoading}
        >
          {t('core:save')}
        </Button>
      </Flex>
    </>
  )
}
