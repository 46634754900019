import { operations } from 'driverama-core/api/driverama/generated/lov'
import { useLovBranchesSearchQuery } from 'driverama-core/api/driverama/lov/lovBranchesSearch'

type LovBranchesSearchResponse = operations['getBranches']['responses']['200']['content']['application/com.driverama-v1+json']

export function getBranch(
  branchList: LovBranchesSearchResponse['content'],
  branchId: string
) {
  return branchList.find(x => x.id === branchId)
}

export function getBranchOpeningHours(
  branchList: LovBranchesSearchResponse['content'],
  branchId: string
) {
  return getBranch(branchList, branchId)?.openingHours
}

export function useBranch(branchId?: string | null) {
  const { data, ...rest } = useLovBranchesSearchQuery(
    {
      filter: { ids: branchId ? [branchId] : [], active: true, types: [] }
    },
    {
      enabled: !!branchId
    }
  )

  return {
    branch:
      data?.content && branchId ? getBranch(data.content, branchId) : undefined,
    ...rest
  }
}
