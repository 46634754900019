import { components } from 'driverama-core/api/driverama/generated/opportunities'
import { isNotNil } from 'driverama-core/utils/types'
import { TFunction } from 'react-i18next'
import { useBranch } from '../../../api/driverama/booking/branches'
import { CustomRBEvent, EventState } from '../../../api/driverama/booking/slots'

export type State = EventState
export type LossReason = components['schemas']['AdminOpportunitySearchResponse']['lossReason']

export const REOPEN_STATES = ['APP_READY'] as const

export const LOST_REASONS = [
  'CANCELLED_WITHOUT_REASON',
  'CAR_ALREADY_SOLD',
  'DUPLICITY',
  'NOT_RELEVANT'
] as const

export type LOST_REASONS_TYPE = typeof LOST_REASONS[number]
export type REOPEN_STATES_TYPE = typeof REOPEN_STATES[number]

export function getState(
  state?: State,
  lossReason?: LossReason
): LossReason | State {
  switch (state) {
    case 'APP_READY':
      return 'APP_READY'
    case 'LOST':
      return lossReason
    default:
      return state
  }
}

export function isLostReason(
  value: LossReason | State
): value is LOST_REASONS_TYPE {
  return LOST_REASONS.includes(value as never)
}

export function isReopenState(
  value: LossReason | State
): value is REOPEN_STATES_TYPE {
  return value === 'APP_READY'
}

export function getOptions(
  t: TFunction<('core' | 'appointment')[]>,
  state?: State
) {
  switch (state) {
    case 'APP_READY':
      return Object.values(LOST_REASONS).map(x => ({
        label: t(
          `appointment:status_${x}` as `appointment:status_${LOST_REASONS_TYPE}`
        ),
        value: x
      }))

    case 'LOST':
      return Object.values(REOPEN_STATES).map(x => ({
        label: t(
          `appointment:status_${x}` as `appointment:status_${REOPEN_STATES_TYPE}`
        ),
        value: x
      }))

    default:
      return []
  }
}

export function useDetailedAddress(appointment: CustomRBEvent['appointment']) {
  const { branch } = useBranch(appointment?.branchId)

  if (appointment?.location === 'BRANCH' && branch) {
    return branch.fullAddress
  }

  return [
    appointment?.address?.address,
    [appointment?.address?.postCode, appointment?.address?.town]
      .filter(isNotNil)
      .join(' ')
  ]
    .filter(isNotNil)
    .join(', ')
}

export function getDetailedCar(event: CustomRBEvent) {
  return [
    [event.car?.makeName, event.car?.modelName].filter(Boolean).join(' '),
    event.car?.made,
    event.car?.licensePlate
  ]
    .filter(Boolean)
    .join(' · ')
}

export function isB2Bopportunity(event: CustomRBEvent) {
  return event.type === 'BUYOUT' && event.source === 'B2B'
}

export type NewState = ReturnType<typeof getState>

export function isNewState(event: CustomRBEvent, value?: State | LossReason) {
  return value && value !== getState(event.state, event.lossReason)
}
