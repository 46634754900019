import { FunctionComponent, ReactNode } from 'react'
import { css } from '@emotion/react'
import { color } from 'driverama-core/styles/variables'
import { size } from 'driverama-core/styles/spacing'
import { TextBody } from 'driverama-core/components/text/Text'
import { Flex } from 'driverama-core/components/Flex'

interface PopupListItemProps {
  children: ReactNode
  label?: string
  justify?: 'between'
  Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export function PopupListItem(props: PopupListItemProps) {
  const { Icon } = props

  return (
    <Flex variant="row" align="center" justify={props.justify} gap={2}>
      {Icon && (
        <Icon
          css={css`
            width: ${size(4)};
            height: ${size(4)};
            color: ${color('night-l-200')};
          `}
        />
      )}

      {props.label && (
        <TextBody
          variant="body"
          size="small"
          css={css`
            color: ${color('night-d-200')};
          `}
        >
          {props.label}
        </TextBody>
      )}

      <TextBody
        variant="body"
        size="small"
        css={css`
          color: ${color('night-d-200')};
        `}
      >
        {props.children}
      </TextBody>
    </Flex>
  )
}
