import { css } from '@emotion/react'
import { ButtonCircle } from 'driverama-core/components/button/Button'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { ReactNode } from 'react'

interface PopupActionButtonProps {
  children: ReactNode
  onClick?: () => void
}

export function PopupActionButton(props: PopupActionButtonProps) {
  return (
    <ButtonCircle
      variant="primary"
      css={css`
        width: ${size(10)};
        height: ${size(10)};
        background: ${color('night-l-700')};
        color: ${color('night-l-100')};
        padding: 0;

        border: none;

        &:hover {
          color: ${color('white')};
        }
      `}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonCircle>
  )
}
