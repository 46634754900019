import {
  Children,
  createElement,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { EventWrapperProps } from 'react-big-calendar'
import { usePopper } from 'react-popper'
import { useWindowSize } from 'react-use'
import { CustomRBEvent } from '../../../api/driverama/booking/slots'
import { useWizard } from '../../../sections/wizard/WizardContext'
import { CalendarContext } from '../Calendar'
import { Popup } from '../popup/Popup'
import { CalendarPortal } from '../portal/Portal'
import {
  calculatePopperTopPlacement,
  getEventTypeClassName
} from './EventWrapper.utils'

export function EventWrapper(
  props: EventWrapperProps<CustomRBEvent> & { children?: ReactNode }
) {
  const { setSelected, updateEvent } = useContext(CalendarContext)
  const { state, dispatch } = useWizard()
  const { height } = useWindowSize()

  const event = props.event

  const eventWrapper = useRef<HTMLDivElement>(null)
  const eventRef = useRef<HTMLDivElement>(null)

  const isLatestCreatedEvent = event.id === state.latestCreatedId

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  // automatically open event with latestCreatedId
  useEffect(() => {
    if (isLatestCreatedEvent) {
      setSelected?.(event)
    }
  }, [isLatestCreatedEvent, setSelected, event])

  useEffect(() => {
    if (props.selected && eventRef?.current && typeof window !== 'undefined') {
      const { pageYOffset } = window
      const elementTopPosition =
        eventRef.current?.getBoundingClientRect().top ?? 0
      const finalElementPosition = pageYOffset + elementTopPosition - height / 2

      window.scrollTo({
        top: finalElementPosition,
        behavior: 'smooth'
      })
    }
  }, [eventRef, props.selected, height])

  const { styles } = usePopper(eventWrapper.current, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      }
    ]
  })

  const child = Children.only(props.children) as ReactElement
  const { onClick, className, ...childProps } = child.props

  return (
    <>
      <div ref={eventWrapper}>
        {createElement(child.type, {
          ...childProps,
          className: [
            className,
            ...getEventTypeClassName(event.type, event)
          ].join(' '),
          ref: eventRef,
          onClick: () => {
            setSelected?.(event)
          }
        })}
      </div>
      <CalendarPortal>
        {props.selected && state.branchId && (
          <Popup
            ref={setPopperElement}
            onClose={() => {
              setSelected?.()

              if (isLatestCreatedEvent) {
                dispatch({
                  type: 'SET_LATEST_CREATED_ID',
                  payload: undefined
                })
              }
            }}
            style={{
              ...styles.popper,
              top: `${calculatePopperTopPlacement(
                props.style?.top,
                popperElement
              )}%`
            }}
            onChangeEvent={eventValues => {
              updateEvent?.(event.id, eventValues)
            }}
            branchId={state.branchId}
            event={event}
          />
        )}
      </CalendarPortal>
    </>
  )
}
