import { useMutation, UseMutationOptions } from 'react-query'

import { operations } from 'driverama-core/api/driverama/generated/opportunities'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'utils/authFetcher'

import { URLS } from 'constants/api'

export type NoteCreateBody = operations['addOpportunityNote']['requestBody']['content']['application/json']
export type NoteCreateResponse =
  | operations['addOpportunityNote']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined

type MutationOpts = UseMutationOptions<
  NoteCreateResponse,
  unknown,
  NoteCreateBody
>

export function useNoteCreateMutation(
  opportunityId: string,
  opts?: MutationOpts
) {
  return useMutation<NoteCreateResponse, unknown, NoteCreateBody>(
    async body => {
      const res = await apiAuthFetcher<NoteCreateResponse>(
        URLS.opportunityNote(opportunityId),
        {
          method: HTTPMethod.POST,
          body
        }
      )
      return res.json
    },
    opts
  )
}
