import { CustomRBEvent } from 'api/driverama/booking/slots'
import { useUpdateOpportunityMutation } from 'api/driverama/opportunity/update'
import { Divider } from 'driverama-core/components/divider/Divider'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { size } from 'driverama-core/styles/spacing'
import IconCheckCircleFilled from 'images/IconCheckCircleFilled.svg'
import { useTranslation } from 'react-i18next'
import { SCalledButton } from './Opportunity.styled'

interface Props {
  event: CustomRBEvent
  onChangeEvent: (values: Partial<CustomRBEvent>) => void
  onClose: () => void
}

export function OpportunityB2BCalled({ event, onChangeEvent, onClose }: Props) {
  const { t } = useTranslation(['core', 'appointment'])

  const {
    mutateAsync: patchOpportunity,
    isLoading: patchOpportunityLoading
  } = useUpdateOpportunityMutation(event.id)

  const handleUpdateb2bCalled = async (b2bPartnerCalled: boolean) => {
    try {
      const response = await patchOpportunity({ b2bPartnerCalled })

      onChangeEvent({
        b2bPartnerCalled: response?.b2bPartnerCalled
      })

      onClose()

      toast({
        type: 'success',
        content: t('appointment:opportunity_updated')
      })
    } catch (error) {
      toast({
        error,
        content: t('core:error_common'),
        type: 'error'
      })
    }
  }

  return (
    <Flex variant="column" css={{ position: 'relative' }}>
      <Spacer size={4} />
      <Divider
        css={{
          position: 'absolute',
          top: 16,
          left: -24,
          width: `calc(100% + ${size(12)})`
        }}
      />
      <Spacer size={4} />

      <Flex variant="row" justify="between" align="center">
        <TextBody color="black">Called the customer?</TextBody>

        <Flex variant="row" gap={2}>
          <CalledButton
            isActive={!!event.b2bPartnerCalled}
            onClick={() => handleUpdateb2bCalled(true)}
            label={t('core:yes')}
            disabled={patchOpportunityLoading}
          />
          <CalledButton
            isActive={event.b2bPartnerCalled === false}
            onClick={() => handleUpdateb2bCalled(false)}
            label={t('core:no')}
            disabled={patchOpportunityLoading}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

type CalledButtonProps = {
  isActive: boolean
  label: string
  onClick: () => void
  disabled?: boolean
}

function CalledButton({
  isActive,
  onClick,
  label,
  disabled
}: CalledButtonProps) {
  return (
    <SCalledButton
      variant="outline"
      isActive={isActive}
      onClick={onClick}
      disabled={disabled}
    >
      {isActive && <IconCheckCircleFilled width={20} height={20} />}
      {label}
    </SCalledButton>
  )
}
