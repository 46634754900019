import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'
import { Button } from 'driverama-core/components/button/Button'
import { TextHeader } from 'driverama-core/components/text/Text'

export const SToolbar = styled.div`
  padding-bottom: ${size(5)};
`

export const SToolbarLabel = styled(TextHeader)`
  color: ${color('night-l-100')};
  width: ${size(80)};
  white-space: nowrap;
  text-align: center;
`

export const SToolbarDate = styled.div`
  min-width: ${size(90)};
`

export const SToolbarButton = styled(Button)`
  border-radius: ${radius('corner-larger')};
`
