import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import IconCross from 'driverama-core/images/icons/IconCross.svg'
import IconEdit from 'driverama-core/images/icons/IconEdit.svg'
import IconTrash from 'driverama-core/images/icons/IconTrash.svg'
import { useRouter } from 'next/router'
import { useWizard } from '../../../sections/wizard/WizardContext'
import { PopupActionButton } from './PopupActionButton'

interface PopupHeaderProps {
  title?: string
  editLink?: string
  handleDelete?: () => void
  onClose: () => void
}

export function PopupHeader(props: PopupHeaderProps) {
  const router = useRouter()
  const { dispatch } = useWizard()

  function handleEdit() {
    if (props.editLink) {
      dispatch({
        type: 'RESET_SLOT'
      })
      router.push(props.editLink)
    }
  }

  return (
    <Flex variant="row" justify="between" align="center">
      {props.title && <TextBody size="small">{props.title}</TextBody>}

      <Flex
        variant="row"
        justify="end"
        gap={4}
        css={{
          flex: 1
        }}
      >
        {props.handleDelete && (
          <PopupActionButton onClick={props.handleDelete}>
            <IconTrash css={{ width: '18px', height: '18px' }} />
          </PopupActionButton>
        )}

        {props.editLink && (
          <PopupActionButton onClick={handleEdit}>
            <IconEdit css={{ width: '18px', height: '18px' }} />
          </PopupActionButton>
        )}

        <PopupActionButton onClick={props.onClose}>
          <IconCross css={{ width: '18px', height: '18px' }} />
        </PopupActionButton>
      </Flex>
    </Flex>
  )
}
