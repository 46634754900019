import { SSidebar, SSidebarInner } from './Sidebar.styled'
import { PropsWithChildren } from 'react'

export function Sidebar(props: PropsWithChildren<unknown>) {
  return (
    <SSidebar>
      <SSidebarInner>{props.children}</SSidebarInner>
    </SSidebar>
  )
}
