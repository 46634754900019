import { css } from '@emotion/react'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function IconsWrapper({ children }: Props) {
  return (
    <Flex
      variant="row"
      align="center"
      css={css`
        gap: ${size(3)};

        & svg {
          height: 14px;
          width: 14px;

          &:last-child {
            margin-right: ${size(2)};
          }
        }
      `}
    >
      <TextBody
        size="small"
        css={{
          lineHeight: 0
        }}
      >
        {children}
      </TextBody>
    </Flex>
  )
}
